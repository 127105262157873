import {
  Box,
} from "../../../components/StyledComponent";
import {
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../../constants";
import { isIOS, isSafari } from "../../../util";
import $ from "jquery";

import LOGO from "../../../assets/images/MEGAN_LOCKUP_RGB.png";
import COVER from "../../../assets/images/M_COVER 1080.png";
import ARICON from "../../../assets/images/ar-icon.png";
import AUDIO from "../../../assets/audio/MEG_AR_Audio.mp3";
import SILENT from "../../../assets/audio/silence.mp3";

const Showcase = () => {
  const audioRef: any = useRef(null);
  const [audioPlayed, setAudioPlayed] = useState<boolean>(false);
  const navigate = useNavigate();
  var audioCtx = new (window.AudioContext)();
  var source = audioCtx.createBufferSource();
  var xhr = new XMLHttpRequest();
  
  useEffect(() => {
    if (audioPlayed) {
      if (isMobile && isIOS) {
        let timer = (isSafari ? 1500 : 2000);
        setTimeout(() => {
          // xhr.open('GET', AUDIO);
          // xhr.responseType = 'arraybuffer';
          // xhr.addEventListener('load', function (r) {
          //   audioCtx.decodeAudioData(
          //     xhr.response, 
          //     function (buffer) {
          //       source.buffer = buffer;
          //       source.connect(audioCtx.destination);
          //       source.loop = true;
          //     });
          //   source.start(0);
          // });
          // xhr.send();
          const audio = audioRef.current as HTMLAudioElement;
          audio.muted = false;
          audio.currentTime = 0;
          audio.play();
        }, timer);
      }
    }
  },[audioPlayed]);

  useEffect(() => {
  },[]);

  function showARView() {
    setAudioPlayed(true);
    if (isMobile && isIOS && isSafari) {
      // window.open('./MEG_AR_FILTER_4.usdz');
      const a = document.createElement("a");
      const img = document.createElement("img");
      a.appendChild(img);
      a.href = './MEG_AR_FILTER_4.usdz';
      a.target = "_blank";
      a.rel = "ar";
      a.click();
    }
  }

  function closeARView() {
    // source.stop()
    const audio = audioRef.current as HTMLAudioElement;
    audio.pause();
    audio.muted = true;
    audio.currentTime = 0;
    setAudioPlayed(false);
  }

  return (
    <Box
      id={RoutePattern.HOME}
      className={`relative flex flex-col justify-center items-center ${isIOS ? isSafari ? "h-[90vh]" : "h-[88vh]" : "h-[93vh]"} md:h-screen w-full overflow-hidden`}
    >
      {!audioPlayed ? (
      <Box className="z-30 flex flex-col justify-center items-center h-full w-full">
        <img src={LOGO} className="absolute top-0 left-0 w-40 md:w-48 3xl:w-56" />
        <img src={COVER} className="w-10/12 md:w-1/4 h-auto" />
        <button className="bg-[#909E77] text-white font-albertusmt py-1 px-3 capitalize rounded-sm mt-5" onClick={showARView}>click here</button>
      </Box>
      ) : (
      <Box className="absolute top-0 left-0 z-[32] flex flex-col justify-center items-center h-full w-full bg-black">
        <img src={LOGO} className="absolute top-0 left-0 w-40 md:w-48 3xl:w-56 cursor-pointer" onClick={closeARView} />
        <img src={ARICON} className="w-40 md:w-48 3xl:w-56 invert" />
        {isMobile && isIOS ? (
          <>
            {isSafari ? null : (
              <iframe src="./MEG_AR_FILTER_4.usdz" className="hidden w-0 h-0"></iframe>
            )}
          </>
        ) : (
          <>
            <Box className="text-white font-albertusmt mt-5 text-sm md:text-2xl 3xl:text-3xl">
              Open this link on an iOS mobile device to see the effect
            </Box>
          </>
        )}
      </Box>
      )}

      <audio ref={audioRef} src={AUDIO} className="hidden" muted loop />
    </Box>
  );
};

export default Showcase;
