export enum RoutePattern {
  HOME = "home",
  RSVP = "RSVP",
  EMERGING_TALENT = "emerging-talent",
  GUESTS = "guests-artists",
  SPECIAL_GUESTS = "special-guests",
  RECAP = "recap",

  ABOUT = "about",
  MUSIC = "music",
  VIDEOS = "videos",
  SUBSCRIBE = "subscribe",
}
