import {
    Box,
} from "../../../components/StyledComponent";
import {
    faClose,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import * as THREE from 'three';
import { useLoader } from "@react-three/fiber";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { USDZLoader } from "three/examples/jsm/loaders/USDZLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../../constants";

import LOGO from "../../../assets/images/MEGAN_LOCKUP_RGB.png";
import ARICON from "../../../assets/images/ar-icon.png";
import AUDIO from "../../../assets/audio/MEG_AR_Audio.mp3";
import SILENT from "../../../assets/audio/silence.mp3";
import { isIOS, isSafari } from "../../../util";

const Egg = () => {
    const audioRef: any = useRef(null);
    const navigate = useNavigate();
    const [playAudio, setPlayAudio] = useState<boolean>(false);

    useEffect(() => {
    //     const audio = audioRef.current as HTMLVideoElement;
    //     if (isMobile && isIOS) {
    //         // window.open('./MEG_AR_FILTER_4.usdz');
    //         if (window.location.pathname === '/egg') {
    //             setTimeout(() => {
    //                 audio.pause();
    //                 audio.src = AUDIO;
    //                 audio.volume = 1;
    //                 audio.currentTime = 0;
    //                 audio.play();
    //             }, 3000);
    //         }
    //     }

    //     window.addEventListener('popstate', function(event) {
    //         if (isMobile && isIOS) {
    //             audio.pause();
    //             audio.currentTime = 0;
    //         }
    //     });
        if(!playAudio) {
            setTimeout(() => {
                setPlayAudio(true);
            }, 3000);
        }
    },[playAudio]);

    function handleClick() {
        // if (isMobile && isIOS) {
        //     const audio = audioRef.current as HTMLVideoElement;
        //     audio.pause();
        //     audio.currentTime = 0;
        // }
        navigate('/');
    }

    return (
        <Box
            id={RoutePattern.HOME}
            className="relative flex flex-col justify-center items-center h-screen w-full overflow-hidden"
        >
            <Box className="z-30 flex flex-col justify-center items-center h-full w-full">
                <img src={LOGO} className="absolute top-0 left-0 w-40 md:w-48 3xl:w-56 cursor-pointer" onClick={handleClick} />
                <img src={ARICON} className="w-40 md:w-48 3xl:w-56 invert" />
                {isMobile && isIOS ? (
                    <>
                        <iframe src={SILENT} allow="autoplay" className="hidden w-0 h-0"></iframe>
                        <iframe src="./MEG_AR_FILTER_4.usdz" className="hidden w-0 h-0"></iframe>
                        <audio ref={audioRef} src={SILENT} className="hidden" autoPlay loop />
                    </>
                ) : (
                    <>
                        <Box className="text-white font-albertusmt mt-5 text-sm md:text-2xl 3xl:text-3xl">
                            Open this link on an iOS mobile device to see the effect
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default Egg;
  