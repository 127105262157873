import { HTMLAttributes } from 'react';

type BoxProps = HTMLAttributes<HTMLDivElement> & {
  motion?: boolean;
};

const Box = ({ ...props }: BoxProps) => {
  return <div {...props}>{props.children}</div>;
};

export default Box;
