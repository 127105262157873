import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isRouteChanged: false,
  currentRoute: '/',
};

export const routesSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setIsRouteChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.isRouteChanged = payload;
    },
    setCurrentRoute: (state, { payload }: PayloadAction<string>) => {
      state.currentRoute = payload;
    },
  },
});

export const { setIsRouteChanged, setCurrentRoute } = routesSlice.actions;
